$tooltip-arrow-margin: 10px;
$tooltip-arrow-overflow: -20px;

.tooltip {
	background: $col-greyDark;
	border-color: $col-greyDark;
	border-radius: $val-borderRadius;
	color: $col-white;
	font-size: $val-fontSizeSmall;
	padding: $val-spacing-s !important;
	opacity: 1;
	pointer-events: none;

	max-width: 900px;

	word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;

	&__trigger-wrapper {
		display: flex;
		align-items: center;

		.icon-help_fill {
			font-size: $val-fontSizeBig !important;
			color: $col-greyFontLight;
			cursor: pointer;
			padding-left: $val-spacing-xs;

			&:hover {
				color: $col-greyDark;
			}
		}

		&--not-allowed {
			cursor: not-allowed;
		}
	}

	&.bs-popover-top {
		margin-bottom: $tooltip-arrow-margin;

		.arrow {
			border-top-color: $col-greyDark;
			bottom: $tooltip-arrow-overflow;
		}
	}

	&.bs-popover-right {
		margin-left: $tooltip-arrow-margin;

		.arrow {
			border-right-color: $col-greyDark;
			left: $tooltip-arrow-overflow;
		}
	}

	&.bs-popover-bottom {
		margin-top: $tooltip-arrow-margin;

		.arrow {
			border-bottom-color: $col-greyDark;
			top: $tooltip-arrow-overflow;
		}
	}

	&.bs-popover-left {
		margin-right: $tooltip-arrow-margin;

		.arrow {
			border-left-color: $col-greyDark;
			right: $tooltip-arrow-overflow;
		}
	}

	&.in {
		opacity: 1 !important;
	}

	.popover-content {
		padding: $val-spacing-s;
	}

	hr {
		margin: $val-spacing-m 0 $val-spacing-s 0;
	}

	&--sidenav {
		left: calc(#{$val-sidebarWidthMin} - #{$val-sidebarWidthMax} + #{$val-spacing-s}) !important; // only applied when collapsed, needs to stick to the item
	}

	&--center-text {
		text-align: center;
	}
}

.tooltip-wrapper {
	position: relative;
	cursor: pointer;
}
