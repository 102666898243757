@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

$var-border: 1px solid $col-greyFontLight;

@mixin status-label {
	text-align: center;
	padding: 0 $val-spacing-m;
	border-radius: $val-borderRadius;
	font-weight: bold;
	font-size: $val-fontSizeSmall;
	min-width: 100px;
	text-overflow: hidden;
	background-color: $col-design-grey12;
}

.order-table {

	&__sub-text {
		color: $col-greyFontLight;
		font-size: $val-fontSizeSmall;
	}

	&__new-status {
		@include status-label;
		color: $col-blue;
	}

	&__in-progress-status {
		@include status-label;
		color: $col-orangeBorder;
	}

	&__completed-status {
		@include status-label;
		color: $col-green;
	}
}

.tooltip-table {
	width: 100%;
	border-collapse: separate;
  }
  
.tooltip-table th,
.tooltip-table td {
	border-bottom: $var-border;
	padding: $val-spacing-xxxs $val-spacing-xxs ;
	border-left: $var-border;
}
  
.tooltip-table th {
	text-align: left;
	border-top: $var-border;
	font-size: $val-fontSizeSmall;
}

.tooltip-table th:last-child {
	text-align: right;
	border-right: $var-border;
}
  
.tooltip-table td:last-child {
	text-align: right;
	border-right: $var-border;
}

.tooltip-table td:last-child {
	border-right: $var-border;
	border-bottom: $var-border;
}

.tooltip-table th:last-child,
.tooltip-table td:last-child {
	white-space: nowrap;
	min-width: 50px;
	width: fit-content;
}

.table-filter {
	display: flex;
}

.completed-filter {
	margin-right: 8px;
	display: flex;
	flex-direction: row;
}

.completed-filter-button {
	border: 1px solid $col-greyMid;
	border-radius: $val-borderRadius;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	display: flex;
	align-items: center;
	background: $col-white;
	width: 200px;
}