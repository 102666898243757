@import '../../../../../../styles/Colors.scss';
@import '../../../../../../styles/Constants.scss';

.section {
	padding: $val-spacing-m;
	background-color: $col-white;
	box-shadow: $val-boxShadow;
	margin-bottom: $val-spacing-s;
}

.label {
	color: $col-design-grey15;
	padding: $val-spacing-s;
	padding-left: 0;
	padding-top: $val-spacing-s;
}

.value {
	padding-left: $val-spacing-s;
	padding-top: $val-spacing-s;
	padding-bottom: $val-spacing-s;
}

.title {
	font-size: $val-fontSizeBig;
	text-transform: uppercase;
	color: $col-greyFontLight;
	margin-bottom: $val-spacing-s;
}

.header {
	font-size: $val-fontSizeXBig;
	text-transform: uppercase;
	font-weight: bold;
}

.header-info {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}

.item-info-container {
	display: flex;
	flex-direction: row;
}

.item-info {
	width: 100%;
}

.categories {
	display: flex;
	flex-direction: row;
	gap: $val-spacing-s;
}

.danger {
	color: $col-red;
}

.restock {
	color: $col-greyFontLight;
	padding: $val-spacing-s;
}

.minimal-quantity-danger {
	font-weight: bold;
	display:flex;
	flex-direction: row;
}

.minimal-value {
 margin-left: $val-spacing-s;
}