@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

.add-button-container {
	padding-top: $val-spacing-m;
	max-width: $val-serachBoxMaxWidth;
}

.vendor-list {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0;
}

.vendor-list-container {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
}

.vendor-list-container-empty {
	color: $col-greyFontLight;
	font-weight: bold;
}

.vendor-list-container-wrapper {
	padding: $val-spacing-m;
	background-color: $col-white;
	box-shadow: $val-boxShadow;
}

.form {
	display: flex;
	flex-direction: row;
}